import React from 'react'
import '../styles/header.scss'
import Layout from "../components/layout";

const Security = () => (
    <Layout>
    <p>security</p>
    </Layout>
);

export default Security
